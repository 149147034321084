<template>
	<div class="shops">
		<h5 class="biaoqi">首页 > 等保一站式服务</h5>
		<div class="contains">
			<div class="con_top">
				<img class="con_top_left" style="width:358px" src="../assets/images/dengbaozhengming/a05.png">
				<div class="con_top_right">
					<h2>等保一站式服务</h2>
					<p class="new_tit">新品大促，万人疯抢中，尽快选购吧！</p>
					<div class="pri_box">
						<p><span class="title">产品价格</span><i>￥</i><span class="price">{{price}}
							</span></p>
						<p><span class="title">说 &nbsp;&nbsp; 明</span><span class="desca">等级保护是我们国家的基本网络安全制度、基本国策，也是一套完整和完善的网络安全管理体系。遵循等级保护相关标准开始安全建设是目前企业单位的普遍要求，也是国家关键信息基础设施保护的基本要求</span></p>
					</div>
					<div class="box_ti">
						<span class="add">地区</span>
						<select  @click="shen($event)">
							<option v-for="(item,index) in oppos" :key="index" :value="index">{{item.title}}</option>
						</select>
						<div class="power" v-show="help == false">更多地区报价,请咨询业务专家！</div>
					</div>
					<div class="box_ti" v-show="help == true">
						<span class="add">分类</span>
						<div class="box_fenlei">
							<span v-for="(item,index) in lists" :key="index" :class="{aa:index==num}" @click="current(index)">{{item.title}}</span>
						</div>
					</div>
					<div class="box_ti" v-show="help == true">
						<span class="add">数量</span>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>



			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
export default{
	props:{
      proData:{
          type:Object,
          default:null
      }
	},
	data(){
		return{
			price:40000,
			num:1,
			value:0,
			help:true,
			lists:[
				{
					title:'二级整改'
				},
				{
					title:'二级测评+整改'
				},
				{
					title:'三级整改'
				},
				{
					title:'三级测评+整改'
				},
			],
			oppos:[
				{
					title:'广东(除深圳)'
				},
				{
					title:'深圳'
				},
				{
					title:'其他'
				},
			]
		}
	},
	methods:{
		
		shen(e){
			console.log(e.target.value);
			this.value = e.target.value;
			if(e.target.value == 0){
				this.help = true
				if(this.num == 0){
					this.price = 26800
				}if(this.num == 1){
					this.price = 40000
				}if(this.num == 2){
					this.price = 56800
				}if(this.num == 3){
					this.price = 70000
				}
			}
			if(e.target.value == 1){
				this.help = true
				if(this.num == 0){
					this.price = 26800
				}if(this.num == 1){
					this.price = 45000
				}if(this.num == 2){
					this.price = 56800
				}if(this.num == 3){
					this.price = 75000
				}
			}if(e.target.value == 2){
				this.price = 26800 + '起';
				this.help = false
			}
		},
		current(index){
			this.num = index
			if(this.num == 0 && this.value == 0){
				this.price = 26800
			}if(this.num == 1 && this.value == 0){
				this.price = 40000
			}if(this.num == 2 && this.value == 0){
				this.price = 56800
			}if(this.num == 3 && this.value == 0){
				this.price = 70000
			}if(this.num == 0 && this.value == 1){
				this.price = 26800
			}if(this.num == 1 && this.value == 1){
				this.price = 45000
			}if(this.num == 2 && this.value == 1){
				this.price = 56800
			}if(this.num == 3 && this.value == 1){
				this.price = 75000
			}

		},
	},
	mounted(){
		console.log(this.proData)
	}
};


</script>

<style type="text/css" scoped  lang="less">
.shops{


.biaoqi{
	line-height: 32px;
	font-size:14px;
	padding-left:20px;
	color:#474747;
}
.contains{
	padding:20px;
	background: #fff;
}
.con_top{
	overflow:hidden;
}
.con_top_left{
	float:left;
	width:358px;
}

.con_top_right{
	float: right;
	width:790px
}
.con_top_right h2{
	font-size: 25px;
	font-weight: 300;
	color: #474747;
	line-height: 42px;
}
.new_tit{
	background:url('../assets/images/b02.png');
	height:30px;
	line-height: 30px;
	font-size:12px;
	color:#fff;
	padding-left:20px;
}
.pri_box{
	background:url('../assets/images/b03.png');
	height:127px;
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
	font-size: 15px;
	color: #474747;
	line-height: 42px;
	display: inline-block;
	width:60px;
	margin-left:20px;
	margin-right: 20px;
	text-align: center;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #C10909;
	line-height: 47px;
}
.pri_box  .price{
	font-size: 35px;
	font-weight: bold;
	color: #C10909;
	position: relative;
	top:3px;
}
.pri_box .desca{
    width: 655px;
	font-size: 13px;
	font-weight: 300;
	color: #474747;
	line-height: 25px;
	display: inline-block;
	position:relative;
	top:10px;
	right:30px;
	float:right;
	display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
}
.box_ti{
   margin-top:5px;
}
.box_ti .add{
	font-size: 15px;
	color: #474747;
	line-height: 42px;
	display: inline-block;
	width:60px;
	margin-left:20px;
	margin-right: 20px;
	text-align: center;
}
.box_ti select{
	width:112px;
	height:32px;
    border: 1px solid #CFCDCD;
}
.box_fenlei{
	display: inline-block;
}
.box_ti .power{
	    font-size: 15px;
    color: #474747;
    line-height: 42px;
    display: inline-block;
    width: 300px;
    margin-left: 8px;
    margin-right: 20px;
    text-align: left;
}
.box_fenlei span{
    width: 112px;
	height: 32px;
	display: inline-block;
	border: 1px solid #CFCDCD;
	font-size: 13px;
	font-weight: 300;
	color: #717171;
	text-align: center;
	line-height: 32px;
	margin-right:19px;
	cursor: pointer;
}
.box_fenlei .aa{
	background-color: #0099CC;
}
.box_ti .numbers{
    width: 51px;
	height: 31px;
	border: 1px solid #CFCDCD;
	font-size: 13px;
	font-weight: 300;
	text-align: center;
	color: #717171;
	line-height: 30px;
}
.box_bottom{
	margin-top:10px;
	margin-left:30px;
}
.box_bottom a{
	width: 133px;
	height: 36px;
	background: #FF7F02;
	font-size: 15px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 36px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}

}

</style>
